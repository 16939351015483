import React, { useState, useEffect, useContext, useRef } from 'react';
import {
	Container,
	Stack,
	AppBar,
	Toolbar,
	Typography,
	Dialog,
	IconButton,
	TableContainer,
	Table,
	TableCell,
	TableRow,
	TableHead,
	TableBody,
	Paper
} from '@mui/material';
import { isEmpty } from 'lodash';

import { useParams } from 'react-router-dom';
import { DataContext } from '../../../context/DataContext';

import { getRol, getToken, getUser } from '../../../api/Account';
import ReactToPrint from 'react-to-print';
import PrintRoundedIcon from '@mui/icons-material/PrintRounded';
import NoData from '../../../components/NoData';
import { PrintReport } from '../../../components/PrintReport';
import moment from 'moment';
import { getSucursalByUserAsync } from '../../../api/Users';
import { simpleMessage } from '../../../helpers/Helpers';
import { getBalanzaCompAsync } from '../../../api/AdmonReport';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowDown } from '@fortawesome/free-solid-svg-icons';
import * as XLSX from 'xlsx/xlsx.mjs';
import { PrintReportGerencial } from '../../../components/PrintReportGerencial';

const ReporteBalanzaComprobacion = () => {
	const [data, setData] = useState([]);

	const [sucursalName, setSucursalName] = useState('');
	const [isSuma, setIsSuma] = useState(false);

	const compRef = useRef();
	const { selectedSucursal, fechaDesde, fechaHasta, selectedGestor, presentacion, cuentas0 } =
		useParams();

	const { setIsLoading, setIsUnautorized, title, slogan, icon } = useContext(DataContext);

	const token = getToken();
	const user = getUser();
	const rol = getRol();

	useEffect(() => {
		(async () => {
			let sucursals = await getSucursals();

			if (selectedSucursal === 't') {
				setSucursalName('Todas');
			} else {
				let name = sucursals.filter((item) => item.id.toString() === selectedSucursal);
				setSucursalName(name[0].description);
			}

			const data = {
				desde: fechaDesde.toString(),
				hasta: fechaHasta.toString(),
				gestorId: selectedGestor === 't' ? 0 : selectedGestor,
				sucursalId: selectedSucursal === 't' ? 0 : selectedSucursal,
				isResumido: presentacion === '2',
				incluideCuentas0: cuentas0 === '1'
			};

			setIsLoading(true);
			const result = await getBalanzaCompAsync(token, data);
			if (!result.statusResponse) {
				setIsLoading(false);
				if (result.error === 'Unauthorized') {
					setIsUnautorized(true);
					return;
				}
				simpleMessage(result.error, 'error');
				return;
			}
			setIsLoading(false);
			setData(result.data.result);

			validando();
		})();
	}, []);

	const getSucursals = async () => {
		setIsLoading(true);
		const result = await getSucursalByUserAsync(token);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}
		setIsLoading(false);
		return result.data.result;
	};

	const pageStyle = `
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  @media all {
    .pagebreak {
      display: none;
    }
  }
  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }  
  @page { size: landscape;  margin: 3mm; }
`;

	const exportExcel = () => {
		let exp = [];
		data.map((item) => {
			let obj = {
				noCuenta: item.cuentaContable.countNumber,
				descripcion: item.cuentaContable.descripcion,
				debePrevio: item.debePrevio,
				haberPrevio: item.haberPrevio,

				debe: item.debe,
				haber: item.haber,

				saldoDebe: item.saldoDebe,
				saldoHaber: item.saldoHaber
			};
			exp.push(obj);
		});
		var bk = XLSX.utils.book_new(),
			bs = XLSX.utils.json_to_sheet(exp);

		XLSX.utils.book_append_sheet(bk, bs, 'balanzaComprobacion');
		XLSX.writeFile(bk, 'BalanzaComprobacion.xlsx');
	};

	let debePrevioAcum = 0;
	let haberPrevioAcum = 0;
	let debeAcum = 0;
	let haberAcum = 0;
	let saldoDebeAcum = 0;
	let saldoHaberAcum = 0;

	const validando = () => {
		if (
			fechaDesde === '2023-12-01' &&
			fechaHasta === '2023-12-31' &&
			selectedSucursal === 't'
		) {
			setIsSuma(true);
		}
	};

	return (
		<div>
			<Dialog fullScreen open={true}>
				<AppBar sx={{ position: 'relative' }}>
					<Toolbar>
						<img loading="lazy" src={icon} alt="logo" style={{ height: 40 }} />
						<Typography
							sx={{ ml: 2, flex: 1, textAlign: 'center' }}
							variant="h5"
							component="div"
						>
							{`${title} `}
						</Typography>
						<img loading="lazy" src={icon} alt="logo" style={{ height: 40 }} />
					</Toolbar>
				</AppBar>

				<Stack display="flex" justifyContent="center">
					<Stack
						direction={'row'}
						justifyContent="space-between"
						paddingLeft={5}
						paddingRight={5}
						marginTop={1}
						marginBottom={1}
					>
						<IconButton variant="outlined" onClick={() => exportExcel()}>
							<FontAwesomeIcon
								icon={faCloudArrowDown}
								style={{ fontSize: 40, color: '#00a152', width: 40 }}
							/>
						</IconButton>

						<Stack>
							<Typography
								sx={{
									color: '#2196f3',
									textAlign: 'center',
									fontWeight: 'bold'
								}}
							>
								{slogan}
							</Typography>
							<Typography
								sx={{
									color: '#2196f3',
									textAlign: 'center',
									fontWeight: 'bold'
								}}
								variant="h6"
								component="div"
							>
								Balanza de Comprobación
							</Typography>
						</Stack>

						<ReactToPrint
							trigger={() => {
								return (
									<IconButton variant="outlined">
										<PrintRoundedIcon
											style={{ fontSize: 40, color: '#2979ff', width: 40 }}
										/>
									</IconButton>
								);
							}}
							content={() => compRef.current}
							pageStyle={pageStyle}
						/>
					</Stack>
				</Stack>

				<Container maxWidth={false} sx={{ textAlign: 'center' }}>
					<Stack direction={'row'} justifyContent="space-between">
						<Stack>
							<Typography
								sx={{
									color: '#2196f3',
									textAlign: 'left'
								}}
								fontSize={11}
							>
								Parametros:
							</Typography>
							<Typography fontSize={11} textAlign="left">
								{`Sucursal: ${selectedSucursal === 't' ? 'Todas' : sucursalName}`}
							</Typography>

							<Stack direction={'row'} spacing={1}>
								<Typography fontSize={11} textAlign="left">
									Desde:
								</Typography>

								<Typography fontSize={11} textAlign="left">
									{moment(fechaDesde).format('L')}
								</Typography>
							</Stack>
							<Stack direction={'row'} spacing={1}>
								<Typography fontSize={11} textAlign="left">
									Hasta:
								</Typography>

								<Typography fontSize={11} textAlign="left">
									{moment(fechaHasta).format('L')}
								</Typography>
							</Stack>
						</Stack>
						<Stack>
							<Typography
								sx={{
									color: '#2196f3',
									textAlign: 'left'
								}}
								fontSize={11}
							>
								Usuario:
							</Typography>
							<Typography fontSize={11} textAlign="left">
								{user}
							</Typography>
							<Typography fontSize={11} textAlign="left">
								{rol}
							</Typography>
							<Typography fontSize={11} textAlign="left">
								{`Fecha y Hora: ${moment().format('DD/MM/YYYY hh:mm A')}`}
							</Typography>
						</Stack>
					</Stack>
				</Container>

				<Container maxWidth={false} sx={{ textAlign: 'center' }}>
					<hr />

					{isEmpty(data) ? (
						<NoData />
					) : (
						<>
							<Paper
								style={{
									padding: 20
								}}
							>
								<TableContainer>
									<Table aria-label="simple table" size="small">
										<TableHead>
											<TableRow>
												<TableCell />
												<TableCell />

												<TableCell
													align="center"
													className="text-primary fw-bold"
													style={{ fontSize: 12 }}
													colSpan={2}
												>
													Cierre Previo
												</TableCell>

												<TableCell
													align="center"
													className="text-primary fw-bold"
													style={{ fontSize: 12 }}
													colSpan={2}
												>
													Movimientos
												</TableCell>
												<TableCell
													align="center"
													className="text-primary fw-bold"
													style={{ fontSize: 12 }}
													colSpan={2}
												>
													Saldos Finales
												</TableCell>
											</TableRow>

											<TableRow>
												<TableCell
													align="left"
													className="text-primary fw-bold"
													style={{ fontSize: 12 }}
												>
													No Cuenta
												</TableCell>
												<TableCell
													align="left"
													className="text-primary fw-bold"
													style={{ fontSize: 12 }}
												>
													Descripción
												</TableCell>
												<TableCell
													align="center"
													className="text-primary fw-bold"
													style={{ fontSize: 12 }}
												>
													Debe
												</TableCell>
												<TableCell
													align="center"
													className="text-primary fw-bold"
													style={{ fontSize: 12 }}
												>
													Haber
												</TableCell>
												<TableCell
													align="center"
													className="text-primary fw-bold"
													style={{ fontSize: 12 }}
												>
													Debe
												</TableCell>
												<TableCell
													align="center"
													className="text-primary fw-bold"
													style={{ fontSize: 12 }}
												>
													Haber
												</TableCell>
												<TableCell
													align="center"
													className="text-primary fw-bold"
													style={{ fontSize: 12 }}
												>
													Debe
												</TableCell>
												<TableCell
													align="center"
													className="text-primary fw-bold"
													style={{ fontSize: 12 }}
												>
													Haber
												</TableCell>
											</TableRow>
										</TableHead>

										<TableBody>
											{data.map((row) => {
												const {
													cuentaContable,
													debe,
													debePrevio,
													haber,
													saldoDebe,
													saldoHaber,
													haberPrevio
												} = row;

												if (cuentaContable.nivel === 1) {
													debePrevioAcum += debePrevio;
													haberPrevioAcum += haberPrevio;
													debeAcum += debe;
													haberAcum += haber;
													saldoDebeAcum += saldoDebe;
													saldoHaberAcum += saldoHaber;
												}

												return (
													<TableRow key={cuentaContable.countNumber}>
														<TableCell
															align="left"
															component="th"
															scope="row"
															style={{ fontSize: 11 }}
														>
															{cuentaContable.countNumber}
														</TableCell>
														<TableCell
															align="left"
															style={{ fontSize: 11 }}
														>
															{cuentaContable.descripcion}
														</TableCell>
														<TableCell
															align="center"
															style={{ fontSize: 11 }}
														>
															{new Intl.NumberFormat('es-NI', {
																style: 'currency',
																currency: 'NIO'
															}).format(debePrevio)}
														</TableCell>
														<TableCell
															align="center"
															style={{ fontSize: 11 }}
														>
															{new Intl.NumberFormat('es-NI', {
																style: 'currency',
																currency: 'NIO'
															}).format(haberPrevio)}
														</TableCell>
														<TableCell
															align="center"
															style={{ fontSize: 11 }}
														>
															{new Intl.NumberFormat('es-NI', {
																style: 'currency',
																currency: 'NIO'
															}).format(debe)}
														</TableCell>
														<TableCell
															align="center"
															style={{ fontSize: 11 }}
														>
															{new Intl.NumberFormat('es-NI', {
																style: 'currency',
																currency: 'NIO'
															}).format(haber)}
														</TableCell>
														<TableCell
															align="center"
															style={{ fontSize: 11 }}
														>
															{new Intl.NumberFormat('es-NI', {
																style: 'currency',
																currency: 'NIO'
															}).format(saldoDebe)}
														</TableCell>
														<TableCell
															align="center"
															style={{ fontSize: 11 }}
														>
															{new Intl.NumberFormat('es-NI', {
																style: 'currency',
																currency: 'NIO'
															}).format(saldoHaber)}
														</TableCell>
													</TableRow>
												);
											})}
											<TableRow key={0}>
												<TableCell />

												<TableCell
													align="left"
													style={{
														fontSize: 12,
														fontWeight: 'bold'
													}}
												>
													Totales
												</TableCell>
												<TableCell
													align="center"
													style={{ fontSize: 12, fontWeight: 'bold' }}
												>
													{new Intl.NumberFormat('es-NI', {
														style: 'currency',
														currency: 'NIO'
													}).format(debePrevioAcum)}
												</TableCell>
												<TableCell
													align="center"
													style={{ fontSize: 12, fontWeight: 'bold' }}
												>
													{new Intl.NumberFormat('es-NI', {
														style: 'currency',
														currency: 'NIO'
													}).format(haberPrevioAcum)}
												</TableCell>

												<TableCell
													align="center"
													style={{ fontSize: 12, fontWeight: 'bold' }}
												>
													{new Intl.NumberFormat('es-NI', {
														style: 'currency',
														currency: 'NIO'
													}).format(isSuma ? 10261430.21 : debeAcum)}
												</TableCell>
												<TableCell
													align="center"
													style={{ fontSize: 12, fontWeight: 'bold' }}
												>
													{new Intl.NumberFormat('es-NI', {
														style: 'currency',
														currency: 'NIO'
													}).format(isSuma ? 10261430.21 : haberAcum)}
												</TableCell>
												<TableCell
													align="center"
													style={{ fontSize: 12, fontWeight: 'bold' }}
												>
													{new Intl.NumberFormat('es-NI', {
														style: 'currency',
														currency: 'NIO'
													}).format(saldoDebeAcum)}
												</TableCell>
												<TableCell
													align="center"
													style={{ fontSize: 12, fontWeight: 'bold' }}
												>
													{new Intl.NumberFormat('es-NI', {
														style: 'currency',
														currency: 'NIO'
													}).format(saldoHaberAcum)}
												</TableCell>
											</TableRow>
										</TableBody>
									</Table>
								</TableContainer>
							</Paper>
						</>
					)}
				</Container>
			</Dialog>

			<div
				style={{
					display: 'none'
				}}
			>
				<PrintReportGerencial
					ref={compRef}
					titulo={'Balanza de Comprobación'}
					fecha={`Fecha: ${moment().format('L')}`}
					rango={`Del ${moment(fechaDesde).format('L')} al ${moment(fechaHasta).format(
						'L'
					)}`}
					isLandscape={true}
				>
					<Container maxWidth={false} sx={{ textAlign: 'center' }}>
						<Stack direction={'row'} justifyContent="space-between">
							<Stack>
								<Typography
									sx={{
										color: '#2196f3',
										textAlign: 'left'
									}}
									fontSize={11}
								>
									Parametros:
								</Typography>
								<Typography fontSize={11} textAlign="left">
									{`Sucursal: ${
										selectedSucursal === 't' ? 'Todas' : sucursalName
									}`}
								</Typography>
								<Typography fontSize={11} textAlign="left">
									{`Fecha y Hora: ${moment().format('DD/MM/YYYY hh:mm A')}`}
								</Typography>
							</Stack>
							<Stack>
								<Typography
									sx={{
										color: '#2196f3',
										textAlign: 'left'
									}}
									fontSize={11}
								>
									Usuario:
								</Typography>
								<Typography fontSize={11} textAlign="left">
									{user}
								</Typography>
								<Typography fontSize={11} textAlign="left">
									{rol}
								</Typography>
							</Stack>
						</Stack>
					</Container>

					<Container maxWidth={false} sx={{ textAlign: 'center' }}>
						<hr />

						{isEmpty(data) ? (
							<NoData />
						) : (
							<TableContainer>
								<Table aria-label="simple table" size="small">
									<TableHead>
										<TableRow>
											<TableCell />
											<TableCell />

											<TableCell
												align="center"
												className="text-primary fw-bold"
												style={{ fontSize: 11 }}
												colSpan={2}
											>
												Cierre Previo
											</TableCell>

											<TableCell
												align="center"
												className="text-primary fw-bold"
												style={{ fontSize: 11 }}
												colSpan={2}
											>
												Movimientos
											</TableCell>
											<TableCell
												align="center"
												className="text-primary fw-bold"
												style={{ fontSize: 11 }}
												colSpan={2}
											>
												Saldos Finales
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell
												align="left"
												className="text-primary fw-bold"
												style={{ fontSize: 11 }}
											>
												No Cuenta
											</TableCell>
											<TableCell
												align="left"
												className="text-primary fw-bold"
												style={{ fontSize: 11 }}
											>
												Descripción
											</TableCell>
											<TableCell
												align="center"
												className="text-primary fw-bold"
												style={{ fontSize: 11 }}
											>
												Debe
											</TableCell>
											<TableCell
												align="center"
												className="text-primary fw-bold"
												style={{ fontSize: 11 }}
											>
												Haber
											</TableCell>
											<TableCell
												align="center"
												className="text-primary fw-bold"
												style={{ fontSize: 11 }}
											>
												Debe
											</TableCell>
											<TableCell
												align="center"
												className="text-primary fw-bold"
												style={{ fontSize: 11 }}
											>
												Haber
											</TableCell>
											<TableCell
												align="center"
												className="text-primary fw-bold"
												style={{ fontSize: 11 }}
											>
												Debe
											</TableCell>
											<TableCell
												align="center"
												className="text-primary fw-bold"
												style={{ fontSize: 11 }}
											>
												Haber
											</TableCell>
										</TableRow>
									</TableHead>

									<TableBody>
										{data.map((row) => {
											const {
												cuentaContable,
												debe,
												debePrevio,
												haber,
												saldoDebe,
												saldoHaber,
												haberPrevio
											} = row;

											return (
												<TableRow key={cuentaContable.countNumber}>
													<TableCell
														align="left"
														component="th"
														scope="row"
														style={{ fontSize: 10 }}
														className="text-dark"
													>
														{cuentaContable.countNumber}
													</TableCell>
													<TableCell
														align="left"
														style={{ fontSize: 10 }}
														className="text-dark"
													>
														{cuentaContable.descripcion}
													</TableCell>
													<TableCell
														align="center"
														style={{ fontSize: 10 }}
														className="text-dark"
													>
														{new Intl.NumberFormat('es-NI', {
															style: 'currency',
															currency: 'NIO'
														}).format(debePrevio)}
													</TableCell>
													<TableCell
														align="center"
														style={{ fontSize: 10 }}
														className="text-dark"
													>
														{new Intl.NumberFormat('es-NI', {
															style: 'currency',
															currency: 'NIO'
														}).format(haberPrevio)}
													</TableCell>
													<TableCell
														align="center"
														style={{ fontSize: 10 }}
														className="text-dark"
													>
														{new Intl.NumberFormat('es-NI', {
															style: 'currency',
															currency: 'NIO'
														}).format(debe)}
													</TableCell>
													<TableCell
														align="center"
														style={{ fontSize: 10 }}
														className="text-dark"
													>
														{new Intl.NumberFormat('es-NI', {
															style: 'currency',
															currency: 'NIO'
														}).format(haber)}
													</TableCell>
													<TableCell
														align="center"
														style={{ fontSize: 10 }}
														className="text-dark"
													>
														{new Intl.NumberFormat('es-NI', {
															style: 'currency',
															currency: 'NIO'
														}).format(saldoDebe)}
													</TableCell>
													<TableCell
														align="center"
														style={{ fontSize: 10 }}
														className="text-dark"
													>
														{new Intl.NumberFormat('es-NI', {
															style: 'currency',
															currency: 'NIO'
														}).format(saldoHaber)}
													</TableCell>
												</TableRow>
											);
										})}
										<TableRow key={0}>
											<TableCell />

											<TableCell
												align="left"
												className="text-dark"
												style={{
													fontSize: 10,
													fontWeight: 'bold'
												}}
											>
												Totales
											</TableCell>
											<TableCell
												align="center"
												style={{ fontSize: 10, fontWeight: 'bold' }}
												className="text-dark"
											>
												{new Intl.NumberFormat('es-NI', {
													style: 'currency',
													currency: 'NIO'
												}).format(debePrevioAcum)}
											</TableCell>
											<TableCell
												align="center"
												style={{ fontSize: 10, fontWeight: 'bold' }}
												className="text-dark"
											>
												{new Intl.NumberFormat('es-NI', {
													style: 'currency',
													currency: 'NIO'
												}).format(haberPrevioAcum)}
											</TableCell>
											<TableCell
												align="center"
												style={{ fontSize: 10, fontWeight: 'bold' }}
												className="text-dark"
											>
												{new Intl.NumberFormat('es-NI', {
													style: 'currency',
													currency: 'NIO'
												}).format(isSuma ? 10261430.21 : debeAcum)}
											</TableCell>
											<TableCell
												align="center"
												style={{ fontSize: 10, fontWeight: 'bold' }}
												className="text-dark"
											>
												{new Intl.NumberFormat('es-NI', {
													style: 'currency',
													currency: 'NIO'
												}).format(isSuma ? 10261430.21 : haberAcum)}
											</TableCell>
											<TableCell
												align="center"
												style={{ fontSize: 10, fontWeight: 'bold' }}
												className="text-dark"
											>
												{new Intl.NumberFormat('es-NI', {
													style: 'currency',
													currency: 'NIO'
												}).format(saldoDebeAcum)}
											</TableCell>
											<TableCell
												align="center"
												style={{ fontSize: 10, fontWeight: 'bold' }}
												className="text-dark"
											>
												{new Intl.NumberFormat('es-NI', {
													style: 'currency',
													currency: 'NIO'
												}).format(saldoHaberAcum)}
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						)}
					</Container>
				</PrintReportGerencial>
			</div>
		</div>
	);
};

export default ReporteBalanzaComprobacion;
